<template>
  <div>
    <b-row class="justify-content-center">
      <service-box
        v-for="(item, index) in services"
        :key="index"
        :item="item"
        @click.native="handleSelected(item)"
        :selected="selectedService"
        :last="index === services.length - 1"
      />
    </b-row>
  </div>
</template>

<script>
/*eslint-disable*/
import serviceBox from "./serviceBox";
import { core } from "@/config/pluginInit";
export default {
  components: {
    serviceBox,
  },
  data() {
    return {
      selectedService: "",
      allSelect: null,
    };
  },
  computed: {
    services() {
      return [
        {
          id: 1,
          img: require("@/assets/images/ibbil/veterinarian/doctor.svg"),
          title: this.$t("veterinary.clinicDetection"),
          value: "1",
        },
        {
          id: 2,
          img: require("@/assets/images/ibbil/veterinarian/home.svg"),
          title: this.$t("veterinary.HomeVisit"),
          value: "2",
        },
        //  {
        //    id: 3,
        //    img: require('@/assets/images/ibbil/veterinarian/phone.svg'),
        //    title: this.$t('veterinary.medicalConsultation'),
        //    value: '3'
        //  },
        //  {
        //    id: 4,
        //    img: require('@/assets/images/ibbil/veterinarian/medicine.svg'),
        //    title: this.$t('veterinary.dispensingMedicines'),
        //  value: '4'
        //  }
      ];
    },
  },
  methods: {
    selected(data) {
      console.log("data");
    },
    handleSelected(box) {
      if (box.id === this.selectedService) {
        this.selectedService = "";
      } else {
        if (parseInt(box.id) === 4 || parseInt(box.id) === 3) {
          core.showSnackbar("error", this.$t("veterinary.commingSoonService"));
        } else {
          this.selectedService = box.id;
          this.allSelect = box;
          this.$emit("changeForm", { isSelected: this.allSelect });
        }
      }
    },
  },
};
</script>
