<template>
  <b-container>
    <doctor-card
      :styles="{ '--cols': 2, '--rows': 2 }"
      :data="info"
      :noBtn="true"
      :noFeats="true"
      :horizontal="true"
    >
      <template v-slot:header>
        <div class="doctor-card-header">
          <h3 class="font-size-25 border-bottom doctor-card-header-title">
            {{ $t("veterinary.reservationData") }}
          </h3>
        </div>
      </template>
      <template v-slot:content>
        <b-row class="justify-content-center">
          <b-col lg="9">
            <b-form
              class="position-relative form-top p-4"
              @submit.prevent="handleSubmit(addNewPayment)"
            >
              <b-row>
                <b-col md="6">
                  <input-form
                    name="fullName"
                    :placeholder="$t('auth.userName')"
                    :label="$t('auth.userName')"
                    v-model="customer_name"
                  />
                </b-col>
                <b-col md="6">
                  <input-form
                    name="phone"
                    :placeholder="$t('auth.phoneNumber')"
                    :label="$t('auth.phoneNumber')"
                    v-model="customer_phone"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <!-- <b-col md="6">
                  <input-form
                    name="numOfCamel"
                    :placeholder="$t('veterinary.NumberOfCamels')"
                    :label="$t('veterinary.NumberOfCamels')"
                    v-model.number="number_of_camels"
                  />
                </b-col> -->
                <b-col md="6">
                  <input-form
                    v-if="info.isSelected.id == 2"
                    name="address"
                    :validate="required"
                    :placeholder="$t('veterinary.address')"
                    :label="$t('veterinary.address')"
                    v-model="address"
                  />
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </template>
    </doctor-card>
    <div class="mt-5">
      <payment-component
        @payWithCard="payWithMaster"
        @payWithWallet="payWithWallet"
      />
    </div>
  </b-container>
</template>

<script>
/*eslint-disable*/

import doctorCard from "./doctorCard";
import paymentComponent from "@/components/payment";
export default {
  components: {
    doctorCard,
    paymentComponent,
  },
  emit: ["requestToDoctor"],
  props: ["info"],
  data() {
    return {
      address: "",
      number_of_camels: "",
      customer_name: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user.name
        : "",
      customer_phone: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user.phone
        : "",
      userInfo: {
        fullName: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.name
          : "",
        phone: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.phone
          : "",
      },
    };
  },
  methods: {
    payWithMaster(data) {
      this.$emit("requestToDoctor", {
        ...data,
        address: this.address,
        number_of_camels: this.number_of_camels,
        customer_name: this.customer_name,
        customer_phone: this.customer_phone,
      });
    },
    payWithWallet(data) {
      if (this.info.isSelected.id == 2) {
        this.$emit("payWithWallet", {
          ...data,
          address: this.address,
          number_of_camels: this.number_of_camels,
          customer_name: this.customer_name,
          customer_phone: this.customer_phone,
        });
      } else {
        this.$emit("payWithWallet", {
          ...data,
          number_of_camels: this.number_of_camels,
          customer_name: this.customer_name,
          customer_phone: this.customer_phone,
        });
      }
    },
  },
};
</script>
