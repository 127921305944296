<template>
  <b-container>
    <doctor-card
      :styles="{ '--cols': 2, '--rows': 2 }"
      :data="info"
      @changeForm="changeForm"
      :noBtn="true"
      :noFeats="true"
      :horizontal="true"
      :appointmentAndReviews="true"
    />
  </b-container>
</template>

<script>
/*eslint-disable*/
import doctorCard from "./doctorCard";
export default {
  props: ["info"],
  components: {
    doctorCard,
  },
  data() {
    return {};
  },
  methods: {
    changeForm(i) {
      this.$emit("changeForm", i);
    },
  },
};
</script>
