<template>
  <b-col sm="6" md="4" lg="3" class="">
    <div
      class="service-box mb-4 text-center d-flex flex-column align-items-center justify-content-between"
      :class="selected === item.id ? 'selected' : ''"
    >
      <div class="service-box-img-wrapper">
        <img :src="item.img" alt="" class="img-fluid service-box-img" />
      </div>
      <h3 class="service-box-title text-center" v-text="item.title"></h3>
    </div>
  </b-col>
</template>

<script>
/*eslint-disable*/
export default {
  props: ["item", "selected", "last"],
  data() {
    return {
      selectedService: "",
      featuresIsOpened: false,
    };
  },
  methods: {
    handleSelected(truck) {
      if (truck === this.selectedService) {
        this.selectedService = "";
      } else {
        this.selectedService = truck;
      }
      this.$emit("selectedBox", truck);
    },
  },
};
</script>
