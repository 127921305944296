<template>
  <div class="iq-card doctor-card m-0" :style="styles">
    <slot name="header"></slot>
    <div
      class="iq-card-body d-flex justify-content-between align-items-center flex-wrap"
      v-if="data.prices"
    >
      <div
        class="doctor-card-main-info d-flex align-items-center"
        :class="{
          'flex-fill': horizontal,
          gap_1: !horizontal,
          gap_2: horizontal,
          ...mainInfoClasses,
        }"
      >
        <div
          class="rounded-circle profile-doctor"
          :style="{
            'background-image': 'url(' + data.personal_picture + ')',
            'background-size': 'cover',
            'background-position': 'center',
            'background-repeat': 'no-repeat',
          }"
        ></div>
        <!--<img :src="data.personal_picture" :alt="data.name" class="img-fluid rounded-circle" :class="{'doctor-img': horizontal}" />-->
        <div class="main-info">
          <h6 class="main-info-subtitle font-size-16">
            {{ $t("veterinary.doctor") }}
          </h6>
          <h6 class="main-info-title font-size-20">{{ data.full_name }}</h6>
          <h6
            class="main-info-subtitle mb-2 font-size-16"
            v-if="data.type == 'employed'"
          >
            {{ data.clinic_name }}
          </h6>
          <!-- {{data.university}} -->
          <h6 class="main-info-subtitle mb-2 font-size-16" v-else>مستقل</h6>

          <!-- description  -->
          <p class="description" v-if="data.description">
            {{ isExpanded ? data.description : truncatedDescription() }}
            <span
              v-if="showMore && data.description.length > 50"
              @click="isExpanded = !isExpanded"
              class="show-more-btn"
              style="cursor: pointer; color: var(--iq-warning) !important"
            >
              {{ isExpanded ? " إخفاء التفاصيل" : " للمزيد" }}
            </span>
          </p>

          <!-- {{data.university}} -->
          <ul class="list-unstyled m-0 d-flex align-items-center gap-1 p-0">
            <li class="font-size-16">({{ 5 }})</li>
            <li v-for="item in [1, 2, 3, 4, 5]" :key="item">
              <i
                class="las la-star icon text-warning"
                :class="{ active: item > 2 }"
              ></i>
            </li>
          </ul>
        </div>
      </div>
      <ul class="list-unstyled m-0 p-0 feats w-20" v-if="!noFeats">
        <li class="d-flex align-items-start font-size-16 feat">
          <span class="icon"><i class="las la-check"></i></span>
          <span class="text">
            {{
              data.examine_method === "appointment"
                ? "ميعاد محدد"
                : "بأولوية الحجز"
            }}
          </span>
        </li>
      </ul>
      <div v-if="horizontal" class="flex-fill doctor-card-info">
        <div class="mb-3" v-if="appointment">
          <h6 class="main-info-subtitle font-size-16">
            $t('veterinary.appointment')
          </h6>
          <h6 class="main-info-title font-size-20">
            {{ days[new Date(data.appointment.date).getDay()] }}
            -
            {{ new Date(data.appointment.date).getDate() }}
            {{ months[new Date(data.appointment.date).getMonth()] }}
            {{ data.appointment.time }}
          </h6>
        </div>
        <div class="mb-3">
          <h6 class="main-info-subtitle font-size-16">
            {{ $t("auth.address") }}
          </h6>
          <h6 class="main-info-title font-size-20">
            {{ data.clinic_address }}
          </h6>
        </div>
        <div class="book-price" v-if="bookPrice">
          <h6 class="main-info-subtitle font-size-16">
            {{ $t("veterinary.reservationPrice") }}
          </h6>
          <h6
            class="main-info-title font-size-25"
            v-if="data.isSelected.id == 3"
          >
            {{ data.prices.consulting }} ريال سعودى
          </h6>
          <h6
            class="main-info-title font-size-25"
            v-if="data.isSelected.id == 1"
          >
            {{ data.prices.clinic_examine }} ريال سعودى
          </h6>
          <h6
            class="main-info-title font-size-25"
            v-if="data.isSelected.id == 2"
          >
            {{ data.prices.outdoor_examine }} ريال سعودى
          </h6>
        </div>
        <div v-else>
          <h6 class="main-info-subtitle font-size-16">
            {{ $t("veterinary.reservationPrice") }}
          </h6>
          <h6
            class="main-info-title font-size-20"
            v-if="data.isSelected.id == 3"
          >
            {{ data.prices.consulting
            }}<span class="currency text-uppercase">sar</span>
          </h6>
          <h6
            class="main-info-title font-size-20"
            v-if="data.isSelected.id == 1"
          >
            {{ data.prices.clinic_examine
            }}<span class="currency text-uppercase">sar</span>
          </h6>
          <h6
            class="main-info-title font-size-20"
            v-if="data.isSelected.id == 2"
          >
            {{ data.prices.outdoor_examine
            }}<span class="currency text-uppercase">sar</span>
          </h6>
        </div>
      </div>
      <div v-if="!horizontal">
        <h6 class="main-info-subtitle font-size-16">
          {{ $t("auth.address") }}
        </h6>
        <h6 class="main-info-title font-size-20">{{ data.clinic_address }}</h6>
      </div>
      <div v-if="!horizontal">
        <h6 class="main-info-subtitle font-size-16">
          {{ $t("veterinary.reservationPrice") }}
        </h6>
        <h6
          class="main-info-title font-size-20"
          v-if="infoSelected.isSelected.id == 1"
        >
          {{ data.prices.clinic_examine
          }}<span class="currency text-uppercase">sar</span>
        </h6>
        <h6
          class="main-info-title font-size-20"
          v-if="infoSelected.isSelected.id == 3"
        >
          {{ data.prices.consulting
          }}<span class="currency text-uppercase">sar</span>
        </h6>
        <h6
          class="main-info-title font-size-20"
          v-if="infoSelected.isSelected.id == 2"
        >
          {{ data.prices.outdoor_examine
          }}<span class="currency text-uppercase">sar</span>
        </h6>
      </div>
      <div v-if="!noBtn">
        <b-button
          class="apointment-btn border-0 p-0"
          variant="secondary"
          @click="changeToChooseTime(data)"
          >{{ $t("veterinary.BookAnAppointment") }}</b-button
        >
      </div>
    </div>
    <slot name="content"></slot>
    <div class="iq-card-body mt-3 pt-0" v-if="appointmentAndReviews">
      <appointments-and-reviews
        :userInfo="data"
        :workTimes="data.work_times"
        @changeForm="changeToChooseTime"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable*/
import appointmentsAndReviews from "./appointmentsAndReviews.vue";
export default {
  props: [
    "data",
    "infoSelected",
    "noBtn",
    "noFeats",
    "horizontal",
    "appointmentAndReviews",
    "mainInfoClasses",
    "styles",
    "bookPrice",
    "appointment",
  ],
  components: {
    appointmentsAndReviews,
  },
  data() {
    return {
      description: "lorem  loremloremloremlorem",
      isExpanded: false, //for control string length
      charLimit: 60, //length max
      months: [
        "يناير",
        "فبراير",
        "مارس",
        "ابريل",
        "مايو",
        "يونيو",
        "يوليه",
        "اغسطس",
        "سبتمبر",
        "اكتوبر",
        "نوفمبر",
        "ديسمبر",
      ],
      days: [
        "الاحد",
        "الاتنين",
        "الثلاثاء",
        "الاربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
      // features: [
      //   'صالة انتظار مرحة',
      //   'سرير للمرافق',
      //   'صالة انتظار مرحة'
      // ]
    };
  },
  computed: {
    features() {
      return [
        this.$t("veterinary.waitingRoom"),
        this.$t("veterinary.utilityBed"),
      ];
    },
  },
  mounted() {
    // Scroll to top by default
    window.scrollTo(-1000, -10000);
  },
  watch: {
    data: {
      handler() {
        // Scroll to top every time 'data' prop changes
        window.scrollTo(0, 0);
      },
      deep: true, // Watch for nested changes as well
    },
  },
  methods: {
    truncatedDescription() {
      return this.data.description.length > this.charLimit
        ? this.data.description.slice(0, this.charLimit) + "....... "
        : this.data.description;
    },
    showMore() {
      return this.data.description.length > this.charLimit;
    },
    changeToChooseTime(i) {
      this.$emit("changeForm", i);
    },
  },
};
</script>
<style scoped>
.description {
  width: 300px;
}
.main-info {
  width: 300px;
}
</style>
