<template>
  <div>
    <h3 class="text-center title">
      {{ $t("veterinary.chooseReservationDate") }}
    </h3>
    <div class="position-relative">
      <Swiper
        :id="`post-slider-homeSlider`"
        :options="swiperOptions"
        :pagination="false"
        :navigation="true"
      >
        <appointment-card
          v-for="(appointment, index) in allDays"
          :key="index"
          :workTimes="workTimes"
          :userInfo="userInfo"
          :data="appointment"
          @chooseAppointment="chooseAppointment"
        />
      </Swiper>
      <div class="arrows">
        <div class="swiper-button-next swiper-controller">
          <i class="las la-angle-right"></i>
        </div>
        <div class="swiper-button-prev swiper-controller">
          <i class="las la-angle-left"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import appointmentCard from "./appointmentCard";
export default {
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.mySwiper) {
        this.$refs.mySwiper.Swiper.update();
      }
    });
  },

  components: {
    appointmentCard,
  },
  props: ["workTimes", "allDays", "userInfo"],
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        // autoplay: true,
        slidesPerView: 3,
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
      },
      appointments: [],
    };
  },
  methods: {
    chooseAppointment(data) {
      if (true) {
        this.$emit("chooseAppointment", data, "appointmentChooseTime");
      }
    },
  },
};
</script>
<style>
.swiper-button-next {
  right: 10px !important;
  left: auto !important;
}

.swiper-button-prev {
  left: 10px !important;
  right: auto !important;
}
</style>
